<script setup lang="ts">
import { computed, PropType } from "vue";

type SizeType = "normal" | "large" | "small";

const props = defineProps({
    dark: {
        type: Boolean,
        default: true
    },
    disabled: {
        type: Boolean,
        default: false
    },
    size: {
        type: String as PropType<SizeType>,
        default: 'normal'
    }
})

const classes = computed(() => {
    let c = ''
    if (props.size == 'large') {
        c = 'text-md p-2'
    } else if (props.size == 'small') {
        c = 'text-xs p-2'
    } else {
        c = 'text-sm h-10 p-5'
    }

    if (props.disabled) {
        return c + ' text-stone-100 bg-stone-300 text-stone-500 pointer-events-none'
    } else {
        if (props.dark) {
            return c + ' text-stone-100 bg-black active:bg-stone-700 hover:bg-stone-600 cursor-pointer'
        } else {
            return c + ' text-stone-900 bg-stone-200 active:bg-stone-300 hover:bg-stone-300 cursor-pointer'
        }
    }
});
</script>
<template>
    <div :class="classes" class="rounded-full flex justify-center items-center font-medium">
        <slot></slot>
    </div>
</template>
